import React, { forwardRef, useEffect, useImperativeHandle, useState ,useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchMethodRequest from '../../../config/service';
import { NavLink, useNavigate } from 'react-router-dom';
import { faShareAlt} from '@fortawesome/free-solid-svg-icons';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../config/configMessages';
import apiCalls from '../../../config/apiCalls';
import DoInputField from '../../Form/Fields/DoInputField';
import { Controller, useForm } from 'react-hook-form';
import { reference } from '@popperjs/core';
import { OverlayPanel } from 'primereact/overlaypanel';
        
const MenuBar = forwardRef(({ setMessages, chatId , searchType ,defaultSearchFilter , type , shareChats,archivedChats,setArchivedChat},ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const [chats ,setChats] = useState([])
  let baseFilter = {"page":1,"limit":20,"sortfield":"pinnedDate","direction" : "desc","secondarySorting":[{"field":"created","direction":"desc"}]};
  const navigate = useNavigate();
  let filterRef = useRef(baseFilter)
  let chatsRef = useRef([])
  const [totalCount,setTotalCount] = useState(0)
  let previousScrollHeightRef = useRef()
  let scrollableRef = useRef();
  const isThrottled = useRef(false);
  // const [archivedChats,setArchivedChat] = useState()
  const overlayRef = useRef(null)
  const [onEdit,setOnEdit]=useState();
  const [selectedChat,setSelectedChat]=useState();

  let {
    handleSubmit, 
    reset,   
    setValue,
    formState: { errors },
    control,
} = useForm();

  useImperativeHandle(ref, () => ({
    createChat(newChat, details) {
      setChats([])
      chatsRef.current = [];
      filterRef.current = baseFilter;
      getChats(newChat,null, details)
    },
    getSharedChats() {
      setChats([])
      chatsRef.current = [];
      filterRef.current = baseFilter;
      handleSharedChats();
    }
  }));

  const getChats = (newChat = false , defaultFilter = null, details) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(defaultSearchFilter) {
        finalFilter.criteria = [defaultSearchFilter]
    }
    fetchMethodRequest('GET',`${apiCalls.chats}?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
          if(newChat){
            if(details?.messages?.chatId) {
              navigate(`/${searchType}/${details.messages.chatId}` , {state : { chat : {...details?.messages, conversationType : details?.conversationType}}})
            } else {
              navigate(`/${searchType}/${resp.chats[0]?._id}`)
            }
          }
        }
      }
    })
  }

  const handleSharedChats = (defaultFilter) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(finalFilter.criteria?.length > 0) {
      finalFilter.criteria = [];
    }
    fetchMethodRequest('GET',`${apiCalls.sharedChats}?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter?.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
        }
      }
    })
  }
  const handleArchiveChats = (defaultFilter) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(finalFilter.criteria?.length > 0) {
      finalFilter.criteria = [];
    }
    fetchMethodRequest('GET', `archive?type=chats&filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.archive && resp.archive?.length > 0){
        if(finalFilter?.page === 1) {
          setChats(resp.archive);
          chatsRef.current = resp.archive
        }
        else {
          chatsRef.current = [...chatsRef.current,...resp.archive];
          previousScrollHeightRef.current = scrollableRef.current.scrollHeight
          setChats(chatsRef.current);
          scrollToCurrentHeight()
        }
      }
    })
    }

  useEffect(() => {
    if(type === 'shared') {
      setChats(null);
      chatsRef.current = null;
      handleSharedChats();
    } else if(type === 'archive'){
      handleArchiveChats();
    }else{
      getChats()
    }
  },[type])

  const navigateToNewChat = () => {
    setMessages([])
  }

  
  const scrollToCurrentHeight = () => {
    if(scrollableRef.current) {
      scrollableRef.current.scrollTop =  previousScrollHeightRef.current - scrollableRef.current.scrollHeight ;
    }
  }

  const onScrollTop = (e) => {
    if (isThrottled.current) {
      return;
    }
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    if(bottom && (chats?.length < totalCount)) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      if(type==='shared') {
        handleSharedChats(currentFilter)
      } else if(type==='archive') {
        handleArchiveChats(currentFilter);

      }
      else {
        getChats(false,currentFilter);
      }  
      isThrottled.current = true;
      setTimeout(() => {
        isThrottled.current = false;
      }, 300);
    }
  }

  const updateChat = async (body , url , method) => {
    return fetchMethodRequest(method , url , body).then((res) => {
      if( (res && res.respMessage)||res.respCode&&res.respCode===206 ) {
        if(res.respMessage?.length > 0) {
          showToasterMessage(res.respMessage, 'success')
        }else if(res.respCode&&res.respCode ===206){
          showToasterMessage("Deleted Successfully ", 'success')
        }
        filterRef.current = baseFilter;
        setChats([]);
        setOnEdit(null);
        chatsRef.current = [];
        if (type === 'shared') {
          handleSharedChats();
        }else if (type === 'archive') {
          handleArchiveChats();
        } else {
          getChats();
        }
      } else if (res && res.errorMessage) {
        showToasterMessage(res.respMessage , 'error');
      }
    })
  }

  const pinChat = (item) => {
    overlayRef.current.hide()
    if (item && (item._id || item.sharedChatId)) {
      let body = {
        pinned: !item?.pinned
      }
      let url = `${apiCalls.chats}/${item._id}`
      if (type === 'shared') {
        url = `${apiCalls.sharedChatUpdate}/${item.sharedChatId}`
      }
      updateChat(body, url, 'PUT')
    } else {
      showToasterMessage(configMessages.chatNotFound, 'error');
    }
  }
  const renameChat = (item) => {
    setValue("rename",item.question)
    setOnEdit(item);
    overlayRef.current.hide()
   
  }
   
  const renameFields =  [
    {
      name: 'rename',
      type: 'text',
      placeholder: '',
      label: '',
      width: 110,
      id: 'rename',
      onBlur:true,
      onKeyPress: "Enter",
      variant:"outlined",
      style: {
        border: 'none',
        height:"10px",
        outline: 'none',
        fontSize: '15px',
        color: 'black',
        backgroundColor: '#f5f5f5',
      }
    },
  ]
  
  const handleEditName=()=>{
  return (<div>
  <form onSubmit={handleSubmit(handleRename)}>
  {renameFields.map((item,index)=>(
    <Controller
      name={item.name}
      control={control}
      render={({ field, fieldState }) => (
      <DoInputField
        input={field}
        id={field.id}
        name={field.name}
        label={""}
        variant={field.variant}
        field={field}
        type={"text"}
        fieldState={fieldState}
        errors={errors}
        style={item.style}
        placeholder={item.placeholder}
        onBlur={field.onBlur}
        onKey={item.onKeyPress}
        submit={handleSubmit(handleRename)}
      />)}
    />))}
  </form>
  </div>)
  }

  const setArchiveChat = (chat,archiveType) => {
    overlayRef.current.hide();
    let url,body;
  if(archiveType=='archive'){  
    url = `archive`;
    body = {
      chatId: chat?._id,
    };
    updateChat(body, url, "POST");
  }else if(archiveType=='unarchive'){
    url=`archive/${chat?._id}`
    updateChat( body,url, "PUT");
  }
  };

  const handleRename = (newData) => {
    let data = { ...onEdit };
    data.question = newData.rename;
    if (data) {
      let body = data;
      let url = `${apiCalls.chats}/${onEdit._id}`;
      updateChat(body, url, "PUT");
    } else {
      showToasterMessage(configMessages.chatNotFound, "error");
    }
  };

  const deleteChat = (item) => {
    overlayRef.current.hide();
    if(item){
      let body = {}
      let url = `${apiCalls.chats}/${item._id}`
      updateChat(body, url, 'DELETE')
    }else{
      showToasterMessage(configMessages.chatNotFound, 'error');
    }

  }
  const showOverlay = (e, chat) => {
    setSelectedChat(chat);
    overlayRef.current && overlayRef.current.toggle(e);
  };


  return (
    <div className='col-sm-3 d-flex flex-column'>
    <div className='d-flex justify-content-evenly flex-wrap menu-buttons mt-1'>
          <NavLink to={'/'+searchType} onClick={navigateToNewChat}>
              <button className={!type ? 'new-chat-button active-button' : 'new-chat-button' } title='New Chat'>
                <FontAwesomeIcon icon="plus" title='New Chat' />
                <span className='ml-2'>New Chat</span>
              </button> 
          </NavLink>
           <NavLink to={'/'+searchType+'?type=archive'}>
              <button className={type=="archive" ? 'new-chat-button active-button' : 'new-chat-button' } title='Archived Chats'>
                <FontAwesomeIcon icon={"archive"} title='Archived Chats' />
                <span className='ml-2'>Archived Chats</span>
              </button> 
          </NavLink>
          { shareChats && <NavLink to={'/'+searchType+'?type=shared'}>
              <button className={type=="shared" ? 'new-chat-button active-button' : 'new-chat-button' } title='Shared Chats'>
                <FontAwesomeIcon icon={faShareAlt} title='Shared Chats' />
                <span className='ml-2'>Shared Chats</span>
              </button> 
          </NavLink>}
      </div>
    <div className="menu-bar chat-menubar" style={{ display: isOpen ? 'block' : 'none' }} onScroll={onScrollTop}>
      <ul className="message-list" ref={scrollableRef}>
        {
          type!="archive"&&chats && chats.length > 0 && chats.map((chat, index) => (
            <NavLink to={type ? `/${searchType}/${chat._id}?type=${type}` : `/${searchType}/${chat._id}`} state={{chat}}>
              <li key={index}  className={(chatId === chat._id ? 'active-chat' : '' )}>
                  <div className='d-flex justify-content-between'>
                  <div className='chat-name' title={chat.question}>{(onEdit?._id===chat._id)?handleEditName():chat.question} </div> 
                  {chat?.pinned && <div className='me-2'> <FontAwesomeIcon icon={faThumbtack}/></div>}
                  <div className='active-chat-options' onClick={(e) => showOverlay(e, chat)}>
                    <FontAwesomeIcon icon="ellipsis-h" />
                  </div>
                  </div>
             </li>
            </NavLink>
          ))
        }
        {
          type=="archive"&&chats && chats.length > 0 && chats.map((chat, index) => (
            // <NavLink to={type ? `/${searchType}/${chat._id}?type=${type}` : `/${searchType}/${chat._id}`} state={{chat}}>
              <li key={index}  className={(chatId === chat._id ? 'active-chat' : '' )}>
                  <div className='d-flex justify-content-between'>
                  <div className='chat-name' title={chat.question}>{(onEdit?._id===chat._id)?handleEditName():!type?chat.question:chat.name} </div> 
                  {chat?.pinned && <div className='me-2'> <FontAwesomeIcon icon={faThumbtack}/></div>}
                  <div className='active-chat-options' onClick={(e) => showOverlay(e, chat)}>
                    <FontAwesomeIcon icon="ellipsis-h" />
                  </div>
                  </div>
             </li>
            // </NavLink>
          ))
        }
      </ul>
    </div>
    <OverlayPanel className='custom-overlay' ref={overlayRef} closeOnEscape={true} appendTo={null}>
    {!type&&selectedChat && (
        <ul className="overlay-list p-2">
          <li className='overlay-list-item'
              title='Pin'
              onClick={() => pinChat(selectedChat)}
           >
             <FontAwesomeIcon
              className='genderIconAlignment  getcursor  width-15 margin-right-9'
              color='green'
              icon='thumbtack'
              data-toggle='tool-tip'
            />{selectedChat.pinned ? "Unpin chat" : "Pin chat"}
           </li>
           <li className='overlay-list-item'
              title='renameChat'
              onClick={() => renameChat(selectedChat)}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor default-icon-color width-15 margin-right-9'
              color='blue'
              icon='pencil-alt'
              data-toggle='tool-tip'
            />Rename
           </li>
           <li className='overlay-list-item'
              title='delete chat'
              onClick={() => deleteChat(selectedChat)}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor width-15 margin-right-9'
              color='brown' 
              icon='trash-alt'
              data-toggle='tool-tip'
            />Delete
           </li>  
            <li className='overlay-list-item'
              title='Arcive chat'
              onClick={() => setArchiveChat(selectedChat,"archive")}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor width-15 margin-right-9'
              color='brown' 
              icon='trash-alt'
              data-toggle='tool-tip'
            />Archive
           </li>
        </ul>
    )}
     {type&&selectedChat&& (
        <ul className="overlay-list p-2">
           <li className='overlay-list-item'
              title='delete chat'
              onClick={() => deleteChat(selectedChat)}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor width-15 margin-right-9'
              color='brown' 
              icon='trash-alt'
              data-toggle='tool-tip'
            />Delete
           </li>  
            <li className='overlay-list-item'
              title='Arcive chat'
              onClick={() => setArchiveChat(selectedChat,"unarchive")}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor width-15 margin-right-9'
              color='green' 
              icon='unlock'
              data-toggle='tool-tip'
            />UnArchive
           </li>
        </ul>
    )}
</OverlayPanel>

    </div>
  );
});

MenuBar.propTypes = {
  messages: PropTypes.array.isRequired,
  onSelectMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onNewChat: PropTypes.func.isRequired,

};

export default MenuBar;
