import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useLocation, useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { Link, NavLink, Navigate } from 'react-router-dom';

// config file
// config file
const Trash = (props) => {
	 const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [displayTable, setDisplayTable] = useState(true);
  const [filterQueryParam,SetFilterQueryParam] = useState(null);
  const [viewType, setViewType] = useState('list');
  const [dataLoadType,setDataLoadType] = useState('folders');
  const [patharray,setPathArray] = useState([])

  const params = useParams();
  let location = useLocation()
  const navigate = useNavigate();

 
  const query = new URLSearchParams(location.search);
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Home");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  useEffect(() => {
    if(query.get('searchId') && query.get('searchName')){
      SetFilterQueryParam({ searchId : query.get('searchId'), searchName : query.get('searchName')})
      getparentFolders()
      getCurrentFolder()
    } else {
      SetFilterQueryParam(null);
      setCurrentFolder(null);
      setPathArray([]);
    }
    setDisplayTable(false);
    setTimeout(() => {
      setDisplayTable(true)
    }, 100);
  },[location])
  function getparentFolders() {
    fetchMethodRequest('GET', `trash/parentFolders?folderId=${query.get('searchId')}`).then((response) => {
    if(response.parentFolders && response.parentFolders.length > 0) {
        setPathArray(response.parentFolders?.slice(1))
      }
    })
  }
  function getCurrentFolder() {
    fetchMethodRequest('GET', apiCalls.trash + '/' + query.get('searchId')).then((response) => {
      if (response && response.respCode) {
        setCurrentFolder(response.details);
      }
    });
  }


  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: false,
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: "200px",
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "trash",
        fileViewType:'noview',
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        displayIcon:true,
        fieldTypes:'fileUpload',
        filter: true,
        redirectFilterField : 'parentFolderId',
        redirecTo : 'trash',
        // iconName : 'folder',
        showIcon : true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: "100px",
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "left",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
        customWidth : 'col-md-6 col-sm-12 pl-0',
        designIcon : 'user',
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: false,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "trashed",
        type: "date",
        placeholder: "Trashed",
        label: "Trashed",
        width: "100px",
        header: "Trashed",
        derivedValue: "trashed=undefined",
        actions: [],
        actionsNumber: [],
        id: "trashed",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDisplayModalFormat,
        textAlign: "left",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.descDateFromat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customWidth : 'col-md-6 col-sm-12 px-0',
        designIcon : 'calendar',
      },
      {
        name: "path",
        fieldName: "path",
        type: "path",
        placeholder: "path",
        value: "",
        label: "path",
        width: "500px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "File Path",
        derivedValue: "path=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "path",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1011,
        isClickable: "",
        searchField: "name",
        fieldType: "path",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "folders",
        searchApi: "folders",
        textAlign: "left",
        show: true,
        field: "path",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      // {
      //   name: "parentFolderId",
      //   fieldName: "parentFolderId",
      //   type: "relateAutoComplete",
      //   placeholder: "Parent Folder",
      //   value: "",
      //   label: "Parent Folder",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Parent Folder",
      //   derivedValue: "parentFolderId=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "parentFolderId",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "false",
      //   isFieldRequired: "false",
      //   required: false,
      //   globalSearchField: "true",
      //   controllerId: 1011,
      //   isClickable: "",
      //   searchField: "name",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: ["name"],
      //   controllerName: "folders",
      //   searchApi: "folders",
      //   textAlign: "left",
      //   show: true,
      //   field: "parentFolderId",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: false,
      //   tDisplay: true,
      // },
      // {
      //   name: "size",
      //   fieldName: "size",
      //   type: "text",
      //   placeholder: "Size",
      //   value: "",
      //   label: "Size",
      //   width: "30px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Size",
      //   derivedValue: "size=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "size",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   textAlign: "left",
      //   show: true,
      //   field: "size",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      //   fieldType : 'customType',
      //   customFieldType : 'memoryConversion',
      //   customWidth : 'col-md-6 col-sm-12',
      // },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const secondaryTableFields = () => {
    let data = [
      {
        name: "matchedContent",
        type: "text",
        placeholder: "Matched Content",
        label: "Matched Content",
        header: "Matched Content",
        width: '500px',
        id: "matchedContent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "customType",
        customFieldType : 'innerHTML',
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "matchedContent",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
        displayIcon:true,
        filter: false,
        redirectFilterField : 'parentFolderId',
        redirecTo : 'myDocuments',
        style : {
          wordWrap: 'break-word',   // Break long words
          wordBreak: 'break-word',   // Break long words if necessary
          whiteSpace: 'normal',
        }
      },
      {
        name: "matches",
        type: "text",
        placeholder: "Matches",
        label: "Matches",
        header: "Matches",
        width: 110,
        id: "matches",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: false,
        field: "matches",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType : 'customType',
        customFieldType : 'matchedContent',
        tDisplay: true,
        displayIcon:true,
        filter: false,
      }, 
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "redirectable",
        fileViewType:'view',
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        displayIcon:true,
        fieldTypes:'fileUpload',
        filter: false,
        redirectFilterField : 'parentFolderId',
        redirecTo : 'myDocuments',
        showIcon : true,
      }, 
    ];
    return data;
  };

  const expandTableFields = () => {
    let data = [
      {
        name: "matchedContent",
        type: "text",
        placeholder: "Matched Content",
        label: "Matched Content",
        header: "Matched Content",
        width: '500px',
        id: "matchedContent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "customType",
        customFieldType : 'innerHTML',
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "matchedContent",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        displayIcon:true,
        filter: false,
        redirectFilterField : 'parentFolderId',
        redirecTo : 'myDocuments',
        style : {
          wordWrap: 'break-word',   // Break long words
          wordBreak: 'break-word',   // Break long words if necessary
          whiteSpace: 'normal',
        }
      },
      {
        name: "page_no",
        type: "text",
        placeholder: "Page No",
        label: "Page No",
        header: "Page No",
        width: 110,
        id: "page_no",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "center",
        globalSearchField: "true",
        show: true,
        field: "page_no",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: false,
        showIcon : true,
        fieldType : 'customType',
        customFieldType: 'pdfLink',
      }, 
    ];
    return data;
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customClass : 'col-sm-12',
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: false,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: false,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const getUploadField = () => {
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
        customClass : 'col-sm-12',
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: false,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "multiFile",
        fieldName: "multiFile",
        type: "fileUpload",
        placeholder: "MultiFile",
        label: "File Upload",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "multiFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "multiFile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: true,
        required: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "fileUpload",
        imagePath: "Files",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.trash}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };



  const customDisplayHeader = () => {
    const basePath = 'Trash';
    return (
      <div className="d-flex flex-wrap justify-content-between">
        <div>
        <a onClick={() => navigate("/trash")} className="heading getcursor">
          {basePath}
        </a>
        </div>
        <div>
        <p>&rarr;{" "}<a href="/myDocuments" style={{fontSize:"12px"}}>Return to My Documents</a></p>
        </div>
      </div>
    );
  };





  return (
    <span>
      { displayTable && 
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={
            rolePermission && rolePermission == "Edit" && dataLoadType === 'folders' ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          sample={false}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          actionsTypes={[]}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={false}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.homes}
          globalSearch={"Folder / File Name / Content"}
          customDisplayHeader={customDisplayHeader}
          // displayName={`Home${foldersState}`}
          displayName= 'Home'
          type={ dataLoadType === 'files' ? 'Files' : 'Folders'}
          routeTo={'trash'}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.trash?.toLowerCase()}
          apiUrl={apiCalls.trash}
          selectedId={params.id}
          sawppingFolders={false}
          showHomeScreenData = {true}
          shareRequired = {true}
          moveRequired = {true}
          fileuplode={true}
           query={query}
          filterQueryParam = {filterQueryParam}
          // assingValueToForms={}
          // assingValueToForm={ currentFolder ? {path : currentFolder?.path?.slice(22), parentFolderId : currentFolder?._id} : null}
          // viewType = {{viewType : viewType , setViewType : setViewType}}
          // dataLoadType = {{dataLoadType : dataLoadType , setDataLoadType : setDataLoadType}}
          // formUrl = {dataLoadType === 'files' ? apiCalls.files : apiCalls.folders}
          uploderequired = {true}
          uploadDetails = { { getFormFields : getUploadField , formUrl : apiCalls.files , type : 'Files' }}
          secondaryResponseKey='files'
          buttonToolTips = {{add : 'Add Folder'}}
          secondaryTableFields={secondaryTableFields}
          expandTableFields={expandTableFields}
        //   softUpdate={true}
        //   softDelete={true}
          trashRequired={true}
        />
      }

    </span>
  );
};
export default Trash;