import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Trash from './components/Trash';

const trash = (props) => (
  <Container>
    <Trash {...props}/>
  </Container>
);

trash.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(trash);
